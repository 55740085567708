body,
html,
#root,
.auth-wrapper {
  /* font-size: 0.875rem !important; */
  /* font-family: "Plus Jakarta Sans" !important; */
  /* font-family: "circular" !important; */
  font-family: "Inter" !important;
  /* font-family: "monospace" !important; */
  /* font-family: Arial, Helvetica, sans-serif !important; */
  /* font-weight: 500; */
  width: 100%;
  height: 100%;
  /* color: rgb(0, 80, 250) !important; */
  background: #ffffff !important;
  min-height: 100vh;
  display: flex;
  /* font-weight: 400; */
  /* line-height: 2.5rem; */
  font-size: 1rem;
  margin: 0;
  border: 0;
  /* position: relative; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
  text-align: left;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navLink:hover {
  /* color: rgb(0, ) */
  text-decoration: underline;
  cursor: pointer;
  background-color: rgba(0, 80, 250, 0.1) !important;
}

.navLink {
  height: 1.563rem;
  padding: 0.25rem;
  color: rgb(0, 80, 250) !important;
}

.navLinkModuleTasks {
  height: 1.563rem;
  /* padding: 0.25rem; */
  user-select: none;
  color: rgb(0, 80, 250) !important;
}
.navLinkModuleTasks:hover {
  /* color: rgb(0, ) */
  text-decoration: underline;
  cursor: pointer;
  /* background-color: rgba(0, 80, 250, 0.1) !important; */
}

.navLinkModuleTasksCompleted {
  color: gray !important;
}

.navBar {
  background-color: rgba(0, 80, 250, 0.05) !important;
  height: 3rem;
  min-height: 3rem;
}

.navBarPythonEditor {
  background-color: transparent !important;
}

.primaryActionButton {
  display: inline-block;
  user-select: none;
  line-height: 1.3;
  border: 1px solid transparent;
  font-size: 0.875rem !important;
  height: 1.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: rgba(0, 80, 250, 0.8) !important;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  color: white;
  padding: 0.313rem 0.625rem;
}

.primaryActionButton:hover {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 80, 250, 1) !important;
}

.primaryActionButton:disabled {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 80, 250, 0.2) !important;
}

.primaryActionButtonDisabled {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 80, 250, 0.5) !important;
}

.primaryActionButtonDisabled:hover {
  cursor: pointer;
  background-color: rgba(0, 80, 250, 0.5) !important;
}

.saveExamButton {
  background-color: white !important;
  border: 4px solid gray !important;
  color: black;
}

.saveExamButton:hover {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: white !important;
  border: 4px solid gray !important;
  color: black;
}

.saveExamButton:disabled {
  background-color: white !important;
  border: gray !important;
  border-width: 4px !important;
  color: gray;
  /* background-color: rgba(0, 80, 250, 0.2) !important; */
}

.greenActionButton {
  background-color: rgba(0, 150, 0, 0.8) !important;
  color: white;
}

.greenActionButton:hover {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 150, 0, 1) !important;
}

.redActionButton {
  background-color: rgba(200, 0, 0, 0.8) !important;
  color: white;
}

.redActionButton:hover {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(200, 0, 0, 1) !important;
}

.redNavLink:hover {
  /* color: rgb(0, ) */
  background-color: rgba(200, 0, 0, 0.1) !important;
}

.redNavLink {
  color: rgba(200, 0, 0, 1) !important;
}

.questionHtmlCss {
  /* line-height: 1.5rem !important; */
  /* font-size: 1rem; */
}

.question-text-container{
  word-break: break-word;
}

.questionHtmlTabSetClass {
    border: 10px solid red;
    background-color: #0d6efd;

}

.questionHtmlTabSetClass2 {
    border: 10px solid red;
    background-color: #0d6efd;
    height: unset;
    max-height: 120px;

}

.mainAppTopNavBar {
  background-color: rgba(0, 80, 250, 0.1) !important;
  height: 3.75rem;
  color: white;
}

.mainAppCourseNavigationLink:hover {
  cursor: pointer;
  background-color: white !important;
}

.mainAppCourseNavigationLink {
  text-decoration: underline;
  height: 1.563rem;
  font-size: 1rem;
  color: black !important;
}

.mainAppCourseCard {
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.063rem 0.25rem;
  transition: all 400ms;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  svg{
    height: 1.5rem;
    width: 1.5rem;
  transition: all 400ms;
  }
  .course-info-tab-container{
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
    
  }
  .course-info-tab-container-info{
    display: flex;
    flex-direction: column-reverse;
    font-size: 0.875rem;
    .title{
      font-size: 0.75rem;
      color: #3c3d42;
    }
  }
}

.mainAppCourseCard:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.50) 0rem 0.063rem 0.25rem;
  svg{
    path{
      fill:#0d6efd;
    }
  }

}
.mainAppCourseCard:active {
  transform: scale(0.9);

}
.shadow-box-container{
  margin-top: 2rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.063rem 0.25rem;
  transition: all 400ms;
  border-radius: 4px;
}
.projectToobar {
  height: 2.9rem;
  /* padding-bottom: 0.188rem; */
  background-color: #27282e;
  color: white;
  position: relative;
}

.projectButton {
  color: white;
  background-color: #27282e;
  height: 2rem;
  border: 0px;
  border-radius: 6.4px;
  /* padding: 0.3rem 0.6rem 0.3rem 0.6rem; */
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  user-select: none;
  /* text-shadow: 0.063rem 0.063rem white, -0.063rem -0.063rem white; */
}
.projectButtonExpanded {
  background-color: #3c3d42;
}

.projectProcessRunButton {
  transform: rotateZ(90deg);
}
.projectProcessStopButton {
  background-color: #c94f4f;
}
.projectProcessStopButton:hover {
  background-color: #b54747 !important;
}
.projectProcessRerunButton {
  background-color: #599e5e;
}
.projectProcessRerunButton:hover {
  background-color: #508e54 !important;
}

.consoleBarRunButton {
  background-color: transparent;
  transform: rotateZ(90deg);
}
.consoleBarRunButton:hover {
  background-color: #ebecf0 !important;
}
.consoleBarStopButton {
  background-color: #c94f4f;
}
.consoleBarStopButton:hover {
  background-color: #ebecf0 !important;
}
.consoleBarRerunButton {
  background-color: #599e5e;
}
.consoleBarRerunButton:hover {
  background-color: #ebecf0 !important;
}

.consoleBarTrashButton {
  color: #8c8f9b;
}

.projectButton:hover {
  background-color: #3c3d42;
}

.projectButtonPLetter {
  background-color: #478dc8;
  height: 1.4rem;
  display: inline-block;
  padding: 0.08rem 0.4rem 0.06rem 0.4rem;
}

.projectButtonArrow {
  color: #717581;
}

.projectButtonDropdown {
  position: absolute;
  background-color: red;
  z-index: 10;
  background-color: white;
  min-width: 10rem;
  padding: 0.4rem;
  border: 0.16px solid #c9ccd5;
  box-shadow: 0 0.125rem 0.25rem 0 #c9ccd5, 0 0.188rem 0.625rem 0 #c9ccd5;
}

.issueButtonDropDown{
    cursor: pointer;
    position: absolute;
    z-index: 10;
    background-color: white;
    min-width: 30px;
    padding: 0.4rem;
    border: 0.16px solid black;
    box-shadow: 0 0.125rem 0.25rem 0 #c9ccd5, 0 0.188rem 0.625rem 0 #c9ccd5; 
}

.projectDefaultMargin {
  margin-left: 0.4rem;
}

.projectDefaultMarginNegative {
  margin-left: -0.4rem;
}

.projectToolbarDropdownButton {
  color: black;
  background-color: white;
  top: 50%;
}

.projectToolbarDropdownButton:hover {
  background-color: #d4e2ff;
  cursor: pointer;
  text-shadow: 0.063rem 0.063rem white, -0.063rem -0.063rem white;
}

.projectFlexLayoutTabClass {
  /* border: 0.125rem solid red !important; */
  visibility: collapse;
  /* background-color: red; */
}

.projectFilesTab {
  background-color: #f7f8fa;
  /* background-color: red; */
}

.projectFileTreeItem {
  background-color: transparent !important;
  color: black !important;
  height: 1.5rem !important;
  margin-top: 0.2rem;
  font-size: 0.9rem;
  text-shadow: 0.063rem 0.063rem white, -0.063rem -0.063rem white;
}

.projectFileTreeItem:hover {
  background-color: transparent !important;
  color: black;
}

.projectFileTreeItemSelected {
  background-color: #d4e2ff !important;
}

.projectFileTreeItemSelected:hover {
  background-color: #d4e2ff !important;
}

.projectFlexLayoutTabContentClassName {
  /* border: 2px solid green !important; */
}

.projectFlexLayoutTabSetClassNameHeader {
  /* border: 2px solid blue !important; */
}

.projectFlexLayoutBorderClassName {
  border: 2px solid pink !important;
  visibility: collapse;
}

.projectFlexClassNameTabStrip {
  /* border: 0.125rem solid #F7F8FA !important; */
}

.navLinkProjectToolBar {
  background-color: transparent;
  border: 0px;
  height: 1.563rem;
  padding: 0.25rem;
  padding: 0rem !important;
}

.fileNavigationTreeItem {
  background-color: transparent;
  border: 0px;
  padding: 0rem;
  user-select: none;
}
.fileNavigationTreeItem:hover {
  cursor: pointer;
}

.consoleBar {
  background-color: #f7f8fa;
  height: 2.2rem;
  min-height: 2.2rem;
}

.projectSeparatorLine {
  border: 1px solid #ecedf1;
}

.consoleBarButton {
  background-color: transparent;
  /* border: 0rem !important; */
  height: 1.6rem !important;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem !important;
}
.consoleBarButton:hover {
  background-color: #ebecf0 !important;
}

.quizOption {
  background-color: rgba(0, 80, 250, 0.05) !important;
  height: 2.5rem;
}

.quizOption:hover {
  cursor: pointer;
  user-select: none;
}
.examExerciseLink {
  text-decoration: underline;
}

.examExerciseLinkSelected {
  text-decoration: none;
}

.examExerciseLink:hover {
  cursor: pointer;
}

.myInlineDecoration {
  background: lightblue;
  font-weight: bold;
}

.expanded-output {
  position: fixed !important;
  left: 0rem !important;
  top: 0rem !important;
  bottom: 0rem !important;
  right: 0rem !important;
  z-index: 100000 !important;
  background: white !important;
}navLinkProjectToolBar

.stepButton {
  padding: 0rem;
  line-height: normal;
  border: none;
  font-size: 0.875rem;
}

.recorderButton {
  line-height: 0.438rem !important;
  font-size: 0.813rem !important;
}

.processTabButton {
  background-color: transparent;
  color: black !important;
}
.processTabButton:hover {
  background-color: #ebecf0 !important;
}
.processTabButtonSelected {
  border-bottom: 3px solid #4c83f1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.processTabCloseButton {
  /* margin-left: 0.313rem; */
  color: #b2b7c5;
}
.processTabCloseButton:hover {
  color: #9194a1;
}

.execTabButton {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0rem;
  border: 1px solid grey;
  border-radius: 5px;
  align-self: center;
  margin-top: 0.125rem;
  background-color: lightgray;
}
.execTabButtonSelected {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0rem;
  border: 2px solid black;
  border-radius: 5px;
  align-self: center;
  margin-top: 0.125rem;
  background-color: white;
}

.shellInputTextArea {
  border: 1px solid red;
  padding: 0rem;
  resize: none;
}

.shellInputTextArea:focus {
  outline: none !important;
  border-color: none;
  box-shadow: none;
}

.consoleButton {
  padding: 0.125rem;
  border: 1px solid black;
  border-radius: 13px;
  line-height: normal;
  font-size: 0.875rem;
  height: 1.563rem;
  width: 1.563rem;
  margin: 0.063rem;
}


.pythonEditorNavItem {
  cursor: pointer;
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.pythonEditorNavItemActive {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 0px;
}

.examTable {
  background-color: rgba(0, 80, 250, 0.1) !important;
  width: 37.5rem;
  padding: 1.875rem;
}

.appHeader {
  width: 100%;
  height: 5rem;
  /* border: 0.063rem solid red; */
  padding: 1rem 6rem 1rem 6rem;
  font-size: 1rem;
}

.appHeaderUserIcon {
  background-color: transparent;
  border: 0px;
}

.appHeaderNavLink {
  height: 1.563rem;
  /* color: rgba(0, 80, 250, 0.8) !important */
  color: black !important;
  background: "transparent";
  border: 0px;
}

.appHeaderNavLink:hover {
  text-decoration: underline;
  cursor: pointer;
  /* background-color: white !important; */
}

.appHeaderContentSeparator {
  height: 0.063rem;
  border: 1px solid rgba(33, 37, 41, 0.2);
  border-width: 1px 0 0 0;
}

.appContent {
  /* padding: 2rem 10rem 2rem 10rem; */
  flex-grow: 1;
}

.appMyCoursesHeader {
  height: 1.563rem;
  /* color: rgba(0, 80, 250, 0.8) !important */
}

.appMyCoursesHeader:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: white !important;
}

.edit-icon{
    &:hover{
        cursor: pointer;
    }
}

.dashboardActionButton {
  display: inline-block;
  user-select: none;
  line-height: 1.3;
  border: 1px solid transparent;
  font-size: 0.875rem !important;
  height: 1.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: black !important;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  color: white;
  padding: 0.313rem 0.625rem;
}

.dashboardActionButton:hover {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.dashboardActionButton:disabled {
  /* color: rgb(0, ) */
  /* text-decoration: underline; */
  cursor: pointer;
  background-color: rgba(0, 80, 250, 0.2) !important;
}

.programTab {
  border-bottom: 0.063rem solid black;
  padding: 1rem;
  flex: 33.33%;
}

.programTabSelected {
  border-bottom: none;
}

.dashboardFooter {
  border-top: 1px solid black;
}

.flex33 {
  flex: 33.333%;
}

.flex50 {
  flex: 50%;
}

.dashboardFooterHeading {
  font-weight: 600;
  padding-bottom: 1rem;
}

.form-verification { 
    &:hover{
        cursor: pointer;
        color: #8e8ec0;
    }
}

.processTerminalStdin {
  outline: none;
  border: 0;
  width: 100%;
  background: transparent;
}


.processStdinFakeInput {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    color: transparent;
    border: transparent;
}

.processStdinFakeInput:focus {
    border: transparent;
    outline-color: transparent;
}


.processStdinFakeInput2 {
    background: transparent;
    color: transparent;
    /* border: transparent; */
    border: 2px solid red;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
}

.processStdinFakeInput2:focus {
    border: transparent;
    outline-color: transparent;
}

.processStdinFakeInput2::selection {
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    /* background: transparent; */
    /* color: transparent; */
    /* border: transparent; */
    background: transparent;
    color: transparent;

}

.fullScreenExpanded {
    position: fixed !important;
    left: 0px !important;
    top: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
    z-index: 100000 !important;
    background: white !important;
}

.flexlayout-container {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.httpApiPlaygroundTab {
    /* background-color: #f7f8fa; */
}

.httpApiPlaygroundUrlContainer {
    display: flex;
    align-items: center;
    height: 1.6rem;
}

.httpApiPlaygroundMethodButton {
    color: white;
    background-color: #27282e;
    height: 100%;
    border: 0px;
    border-radius: 0px;
    /* padding: 0.3rem 0.6rem 0.3rem 0.6rem; */
    /* padding-left: 0.6rem; */
    /* padding-right: 0.6rem; */
    font-size: 0.8rem;
    width: 4rem;
    user-select: none;
    box-shadow: none;
    /* text-shadow: 0.063rem 0.063rem white, -0.063rem -0.063rem white; */
}

.httpApiPlaygroundUrlInput {
  outline: none;
  height: 100%;
  border: none;
  border: 1px solid rgb(207, 207, 207);
  font-family: monospace;
  font-size: 0.8rem;

    /* text-shadow: 0.063rem 0.063rem white, -0.063rem -0.063rem white; */
}

.httpApiPlaygroundMethodDropdown {
  position: absolute;
  z-index: 100;
  background-color: white;
  border: 1px solid black;
  top: 2rem;
  box-shadow: none;
  padding: 0.2rem;
}

.httpApiPlaygroundRequestContainer {
  border: 1px solid rgb(223, 223, 223);
  margin-top: 0.6rem;
  font-size: 0.8rem;
  flex: 50%;
}

.httpApiPlaygroundResponseContainer {
  border: 1px solid rgb(223, 223, 223);
  margin-top: 0.4rem;
  font-size: 0.8rem;
  flex: 50%;
  /* height: 100%; */
}

.httpApiPlaygroundRequestTabItem {
  border: 0px;
  border-right: 1px solid rgb(223, 223, 223);
  font-size: 0.8rem;
  width: 4rem;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  text-align: center;
  box-shadow: none;
  background-color: white;
  border-radius: 0px;
}

.httpApiPlaygroundRequestTabItemSelected {
  border-bottom: 1px solid #979797;
  background-color: #eeeeee;
  margin-bottom: 0px;
}

.httpApiPlaygroundRequestTabGroup {
  border-bottom: 1px solid rgb(223, 223, 223);
  font-size: 0.8rem;
}

.httpApiPlaygroundRequestHeaderInput {
  margin-left: 0.8rem;
  margin-top: 0.4rem;
  padding: 0.1rem;
  outline: none;
  border-radius: none;
  border: 1px solid rgb(214, 214, 214);
}

.httpApiPlaygroundHeaderTrashButton {
  border: none;
  background-color: white;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  margin-right: 0.6rem;
}

.httpApiPlaygroundRequestBodyContainer {
  outline: none;
  border: none;
  background-color: white;
  padding: 0.3rem;
  /* height: 100%; */
}


.httpApiPlaygroundResponseAttributeKeyText {
  outline: none;
  border: none;
  background-color: white;
  padding: 0.2rem;
  color: #999999;
  /* height: 100%; */
}
.httpApiPlaygroundResponseAttributeValueText {
  outline: none;
  border: none;
  background-color: white;
  padding: 0.2rem;
  margin-right: 1rem;
  /* height: 100%; */
}
.resizer-panel{
  height: 6px;
  opacity:0;
  width: 100%;
  cursor: row-resize;
  box-shadow: #4700fb 0px 7px 10px 6px;
  background-color: #4700fb;
  &:hover {
    opacity: 1 !important;
  }
}

.table{
    border: 1px solid black;
    margin-top: 20px;
    margin-left: 50px;
}

.th{
    border: 1px solid black;
    text-align: center;
}

