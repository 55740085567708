@import "src/styles/mixins";
@import "src/styles/colors.module";

*{
    margin: 0;
    padding: 0;
}

.logo{
    &:hover{
        cursor: pointer;
    }
}

.course-header-container{
    @include desktop-default-styles;
    @include tablet-default-styles;
    @include mobile-default-styles;
    padding-top:2rem;
    width: 100%;
    background-color: #FCEDEC;
    display: flex;
    padding-bottom:2rem;
    .course-content-container{
        color: #000000;
        // font-family: 'circular',sans-serif;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        width: 100%;

        .course-content{
            // font-family: 'circular',sans-serif;
            color: #525252;
            margin-top: 8px;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }

        .course-category-duration{
            display: flex;
            flex-direction: row;
            column-gap: 26.5px;
            margin-top: 16px;

            .course-category{
                display: flex;
                flex-direction: row;

                .category-icon{
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .category-level{
            // font-family: 'circular',sans-serif;
            color: #525252;
            font-weight: 400;
            font-size: 13px;
            line-height: 19.5px;
        }

        .course-status{
            display: flex;
            column-gap: 16px;

            .course-status-button{
                margin-top: 32px;
            }

            .status{
                margin-top: 42px;
            }

        }
        .action-button{
            // font-family: 'circular',sans-serif;
            display: inline-block;
            user-select: none;
            line-height: 1.3;
            border: 0.0063rem solid transparent;
            width: 144px;
            font-size: 0.875rem !important;
            height: 40px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
            background-color: #DB3D35;
            border-radius: 0.25rem;
            vertical-align: middle;
            text-align: center;
            color: white;
            padding: 4px 16px 4px 16px;
        }

    }
}

.card-item-component{
    @include desktop-default-styles;
    @include tablet-default-styles;
    @include mobile-default-styles;
    padding-top:2rem
}


.course-card-item{
    max-width: 37.5rem;
    width: 100%;
    padding:0.75rem 1rem;
    @include tablet-mobile{
        max-width: unset;
    }

    background-color: #F3F3F3;

    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    .card-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover{
            cursor: pointer;
        }

        .card-title{
          
            // font-family: 'circular',sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            
        }

        .dropdown-card-icon{
            
            height: 24px;
            width: 24px;
        }
    }
    .card-status{
        .completed-card-content{
            // font-family: 'circular',sans-serif;
            color: #525252;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .course-tasks{
        background-color: #FFFFFF;
        color: #525252;
        border-radius: 0.25rem;
        padding:0.5rem 0.875rem;
        cursor: pointer;
        margin-top: 0.5rem;
        // font-family: 'circular',sans-serif;
        font-weight: 400;
        .task-details{
            display: flex;
            column-gap: 9px;

            .task-checkbox{
                display: flex;

                justify-content: center;
                align-items: center;
                width : 24px;
                height : 24px;
            }
            .task-name{
                // margin-top: 19px;
                font-size: 12px;
                span{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .task-duration{
            display: flex;
            column-gap: 11px;
            margin-top: 4px;

            .task-icon{
                margin-left: 48px;
                height: 18px;
                width: 18px;
            }

            .duration{
                font-size: 12px;
                line-height: 18px;
            }
        }

    }
}