@import "src/styles/mixins";
@import "src/styles/colors.module";
.footerContainer {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  background-color: $BLACK;
  color: $WHITE;
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @include tablet-mobile {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 1rem;
  }
  .footerColumn {
    display: flex;
    max-width: 15rem;
    @include tablet-mobile {
      max-width: unset;
      display: block;
      width: 100%;
    }
    .footerItemExpandButton {
      display: none;
      @include tablet-mobile {
        display: block;
        cursor: pointer;
      }
    }
    .footerColumnTitle {
      font-size: 0.813rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        @include tablet-mobile {
          cursor: pointer;
        }
      }
    }
    gap: 0.5rem;
    flex-direction: column;
    .footerColumnItems {
      font-size: 0.875rem;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .item {
        cursor: pointer;
      }
      @include tablet-mobile {
        display: none;
      }
    }
    .footerColumnItemsMobile {
      @extend .footerColumnItems;

      @include tablet-mobile {
        display: flex;
        padding: 1rem;
        gap: 1rem;
      }
    }
  }
}
