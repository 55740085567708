@import "src/styles/mixins";
@import "src/styles/colors.module";
$TRACK-ITEM-WIDTH: 9.875rem;
.exploreLearningPathComponent {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  background: linear-gradient(to bottom, $WHITE-1, $WHITE);
  .header {
    color: $GREY;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .courseHeader {
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: 0.5rem;
    text-align: center;
  }
  .tracker {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin-top: 2.5rem;
    @include mobile {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      width: max-content;
    }
    .trackItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: $GREY-2;
      max-width: 9.875rem;
      width: 100%;
      font-weight: 600;
      position: relative;
      @include mobile {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      .trackCircle {
        min-width: 1.5rem;
        font-size: 0.813rem;
        min-height: 1.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $BRAND-PRIMARY;
        .innerCircle {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;

          background-color: $PRIMARY-COLOR;
        }
      }
    }

    .timeline {
      width: calc(100% - 9.875rem);
      height: 0.8rem;
      border-bottom: 2px dashed $PRIMARY-COLOR;
      position: absolute;

      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      @include mobile {
        width: unset;
        height: calc(100% - 3rem);
        border-bottom: unset;
        border-right: 2px dashed $PRIMARY-COLOR;
        width: 0.8rem;
        right: unset;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .innerCoursesCard {
    display: flex;
    margin-top: 1rem;
    gap: 2.5rem;
    margin-bottom: 3rem;
    .mainCourseCard {
      max-width: 11rem;
      width: 100%;
      @include tablet-mobile {
        max-width: 100%;
      }
    }
    @include tablet-mobile {
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}
