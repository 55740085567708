@import "src/styles/mixins";
@import "src/styles/colors.module";

  .login-container {
    @include desktop-default-styles;
    @include tablet-default-styles;
    @include mobile-default-styles;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    .login-input {
        width: 100%;
        margin-top: 1.25rem;
        input{
            margin-top: 0.5rem;
        }
      }
    .login-form {
        width: 25rem;
        padding:1rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 0.5rem;
        h3{
            color:$GREY-2;
            font-weight:600;
        }
      }
          .form-input-row {
              display: flex;
              justify-content: space-between;
    
              &-with-button {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
              }
          }
          .redirection-link-container{
            font-size:1rem;
            a{
                font-size: 1em;
                text-decoration: none;
                &:hover{
                font-weight: 700;

                }
                transition: all 400ms;
            }
          }
          .googleButtonDiv{
            width: 100%;
            margin-top: 1rem;
          }
  }
  
  
  
  