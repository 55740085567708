@import "src/styles/mixins";
@import "src/styles/colors.module";
.landingPage {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 3.75rem);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include tablet-mobile {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .leftSide {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    .landingMainHeader {
      font-size: 3rem;
      font-weight: 700;
      @include tablet {
        font-size: 2.8rem;
      }
      @include mobile {
        font-size: 2rem;
      }
    }
    .landingSubHeader {
      display: flex;
      text-align: left;
      flex-direction: column;
      font-size: 1.125rem;
      font-weight: 500;
      gap: 1rem;
      color: $GREY;
    }
  }
  .rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    .rightSideImage {
      max-width: 35rem;
      object-fit: contain;
      border-radius: 1rem;
      height: max-content;

      @include tablet {
        max-width: calc(100vw - 12.5rem);
      }
      @include mobile {
        max-width: calc(100vw - 2rem);
      }
      @include mobile-mini {
        max-width: calc(100vw - 2rem);
      }
    }
  }
}
