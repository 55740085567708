@import "src/styles/mixins";

.featuresListPanelComponent {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  display: flex;
  flex-direction: column;
  gap: 4.875rem;
  @include tablet-mobile {
    gap: 2rem;
  }
}
