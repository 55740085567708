$GREY: #4d4d4d;
$GREY-2: #525252;
$GREY-3: #cfcfcf;
$WHITE: #ffffff;
$WHITE-1: #f5e9e9;
$WHITE-2: #f1edfe;
$WHITE-3: #f3f3f3;
$PRIMARY-COLOR: #db3d35;
$SECONDARY-COLOR: #a535cc;
$BRAND-PRIMARY: #fcedec;
$BLUE-1: #7349f2;
$BLUE-LIGHT: #f5f2ff;
$BLACK: #000000;
$EMPHASIZE-GREY: #e7e7e7;

:export {
  primarybutton: $PRIMARY-COLOR;
  primarybuttontextcolor: $WHITE;
  darkbackgroundcolor: $BLACK;
  white: $WHITE;
}
