@import "src/styles/mixins";
@import "src/styles/colors.module";
.headerComponent {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgb(231, 231, 231);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  @include mobile {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
  }
  .logo {
    cursor: pointer;
  }
  .iconContainer {
    &:focus {
      outline: none;
    }
  }
  .leftSide {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .headerLeftSideButtons {
      display: flex;
      gap: 1.5rem;
      margin-left: 2rem;
      // font-weight: 600;
      @include mobile {
        display: none;
      }
    }
    .headerLinks {
      cursor: pointer;
      display: flex;
      gap: 0.25rem;
      align-items: center;
      position: relative;
      color: $BLACK;
      text-decoration: none;
    
      &:hover {
        color: $BLUE-1;
        background-color: $BLUE-LIGHT;
        
        // font-weight: 600;
      }
    }
  }
  .loginBtn {
    cursor: pointer;
    position: relative;

    user-select: none;
    svg:active {
      transform: scale(0.9);
    }
    .loggedButton {
      svg {
        circle {
          fill: $SECONDARY-COLOR;
        }
        path {
          fill: $WHITE;
        }
      }
    }
    @include mobile {
      display: none;
    }
  }
  .hamburgerMenu {
    display: none;
    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: all 400ms;

      .hamburgerMenuRow {
        width: 2rem;
        border-radius: 0.25rem;
        height: 0.25rem;
        background-color: $BLACK;
        
      }
    }
  }
  .closeHamburgerMenu{
    @extend .hamburgerMenu;
    .hamburgerMenuRow{
      transition: all 400ms;
      &:nth-child(1){
        transform: rotate(45deg)  translate(10px);
      }
      &:nth-child(2){
        transform: rotate(-45deg) translate(8px);
      }
    }
  }
  .headerSubMenu {
    position: absolute;
    border: 1px solid $GREY-2;
    padding: 0.5rem;
    // color: $BLUE-1;
    margin-top: 0.5rem;
    // margin-left: 0.5rem;
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    border-radius: 0.25rem;
    cursor: pointer;
    text-wrap: nowrap;
  }


  .headerSubmenuLinks {
    cursor: pointer;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    position: relative;
    padding: 0.3rem;
    text-decoration: none;
    color: $BLACK;
    &:hover {
      color: $BLUE-1;
      background-color: $BLUE-LIGHT;
      // font-weight: 600;
    }
  }
}
.mobilePanel {
  top: 3.75rem;
  padding: 1rem;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: $WHITE;
  height: 100%;
  z-index: 999;
  width: 100%;
  gap: 2rem;
  .headerLinks{
    text-decoration: none;
    color: $BLACK;
  }
  .mobHeaderContainer {
    
    .mobHeaderContainerItems {
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      
      .addIcon {
        cursor: pointer;
      }
    }
  }
}
