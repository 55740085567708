@import "src/styles/colors.module";

.courseCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid $WHITE-2;
  background-color: $WHITE;
  position: relative;
  justify-content: space-between;
  height: 100%;
  .title {
    font-weight: 700;
    font-size: 1rem;
  }
  .subHeader {
    font-size: 0.875rem;
    font-weight: 400;
    color: $GREY-2;
  }
  .subText{
    font-size: 0.75rem;
    margin: 0.5rem 0rem;
  }
  .timePeriod {
    margin: 1.5rem 0;
    display: flex;
    gap: 0.25rem;
    font-size: 0.813rem;
    color: $GREY-2;
  }
  .cardHighlighter {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.25rem;
    width: 5rem;
    border-radius: 0.25rem;
    background-color: $PRIMARY-COLOR;
  }
}
