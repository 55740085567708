@import "src/styles/mixins";
@import "src/styles/colors.module";

.verification-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    width: 100%;
    margin: 2rem auto;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 0.875rem;

    .detail-field {
        margin-top: 0.5rem;
        font-weight: 700;
        font-size: 1rem;
    }

    .field-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @include mobile {
        max-width: 300px;
        width: 100%;
        margin: 2rem auto;
    }

    .input-field-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

    }

    .verified-button-container {
        margin-top: 1rem;
    }
}



.mobile-verification-container {
    @extend .verification-container;
    max-width: 28rem;

    .phone-field-container {
        display: flex;
        margin-top: 1rem;
        justify-content: center;
        gap: 1rem;
        align-items: center;
    }
}

.program-card-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2rem;
    display: flex;
    border-radius: 0.5rem;
    flex-direction: column;
    max-width: 35rem;
    width: 100%;
    margin: 2rem auto;
    font-size: 1rem;

    @include tablet-mobile {

        max-width: 25rem;

    }

    .title {
        font-size: 2rem;
        font-weight: 600;
    }

    .tab-container {
        display: flex;
        gap: 2rem;
        // justify-content: space-evenly;
        margin: 1rem 0;
    }

    .duration {
        display: flex;
        flex-direction: column;
        // width: 100%;
        justify-content: center;

        font-size: 1.25rem;
        font-weight: 500;

        color: $GREY-2;

        .sub-text {
            display: flex;
            gap: 0.5rem;
            font-weight: 400;
            font-size: 0.875rem;
        }

        svg {
            height: 1.25rem;
            width: 1.25rem;

            path {
                fill: $BLACK;
            }
        }
    }

    .start-info {
        @extend .duration;

    }

    .fee-container {
        font-size: 2.25rem;
        font-weight: 700;
    }

    .notes-container {
        display: flex;
        font-size: 0.75rem;
        font-weight: 400;
        flex-direction: column;
        color: $GREY-2;
        margin-bottom: 1rem;
    }

    .pay-button-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.5rem;

        margin-top: 1rem;

        .inverseVariant {
            background-color: $WHITE !important;
            color: $BLACK;
            border: 1px solid $BLACK;
        }
    }
}


.order-component-card{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2rem;
    display: flex;
    border-radius: 0.5rem;
    flex-direction: column;
    max-width: 24rem;
    width: 100%;
    margin: 2rem auto;
    font-size: 1rem;
    .title{
        font-weight: 600;
        font-size: 1.5rem;
        .order-id{
            font-weight: 400;
            color:$GREY-2;
            font-size:1rem;
        }
    }

    
    .payment-button-container{
        margin-top: 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:1rem;
            position: sticky;
            bottom: 0;
            background-color: $WHITE;
    }
}
.table-container{
    display: flex;
    margin: 1rem 0;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    .table-main-row{
        display: flex;
        font-size: 0.875rem;
        color:$GREY-2;
        justify-content: space-evenly;
        .table-main-header{width: 100%;}
        .table-main-value{
            text-align: right;
            font-weight: 700;
            width: 100%;
            
        }
    }
   
}
.my-orders-container{
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top:2rem;
  padding-bottom:2rem;
  gap:1rem;
  .order-container{
    padding:1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 0.5rem;
    transition: width 2s, height 4s;
  }
  .header-title{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    .title-container{
        display: flex;
        gap: 0.5rem;
        align-items: baseline;
        .order-id{
            color:$GREY
        }
        @include mobile{
            flex-direction: column;
        }
    }
  }.status-tag{
    color:$WHITE;
    padding:0.5rem;
    font-size: 0.75rem;
    border-radius: 0.5rem;
    height: max-content;
    font-weight: 600;
    
  }
  .order-details{
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    .created-date-container{
        font-size: 0.875rem;
        color:$GREY-2
    }
    .amount-field{
        font-weight: 700;
        font-size: 1.25rem;
    }
  }
  .accordion-button-container{
    display:flex;
    justify-content:flex-end;
    .more-order-details-button{
        border: 1px solid $BLACK;
        padding: 0.25rem;
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            height: 1rem;
            width: 1rem;
        }
        &:active{
            transform: scale(0.9) rotate(180deg);
         
          }
          transition: all 1s;
      }
      
  }


}