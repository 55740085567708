@import "src/styles/mixins";
@import "src/styles/colors.module";
.call-us-container {
  width: 100%;
  background-color: $BLUE-1;
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  .call-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: $WHITE-2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .title {
      color: $WHITE;
      font-size: 1.25rem;
      font-weight: 600;
      @include mobile {
        font-size: 1rem;
      }
    }
    .call-us-number {
      font-size: 1.125rem;
      font-weight: 400;
      color: $EMPHASIZE-GREY;
      @include mobile {
        font-size: 0.875rem;
      }
    }
  }
}
