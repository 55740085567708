@import "src/styles/colors.module";

.levelComponent {
  display: flex;
  gap: 0.25rem;
  color: $WHITE;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  .levelStatusContainer {
    display: flex;
    gap: 1px;
    align-items: flex-end;
  }
  .levelStatus {
    border: 1px solid $WHITE;
    width: 0.25rem;
  }
  .levelStatusInverse{
    border: 1px solid $BLACK;
    width: 0.25rem;
  }
}
.levelComponentInverse {
  @extend .levelComponent;
  color:$BLACK;
  font-weight:700
}
