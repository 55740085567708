.custom-text-input{
    font-family:monospace;
    font-size: 0.875rem;
    position: relative;
    overflow: hidden;
    .custom-text-editor-input{
        width: 100%;
        outline: none;
        border: 0;
        display: inline;
        caret-color: transparent;
        word-break: break-all;
        &:focus{
            outline: none;
            border: 0;
        }
    }
    .custom-text-editor-static{
        @extend .custom-text-editor-input;
        display: inline-block;
        width: 30px;

    }
    
    .cloned-custom-input-text{
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        user-select: none;
        pointer-events: none;
        display: inline;
        resize: none;
        caret-color: transparent;
        .cloned-custom-hidden-element{
            opacity: 0
        }
        .cloned-custom-text-editor-input-text{
            width: 100%;
            outline: none;
            border: 0;
            opacity: 0;
            display: initial;
            word-break: break-all
        }
    }
    
}

.caret{
    margin-top: 1px;
    height: 1rem;
    width: 0.5rem;
    display: inline-block;
    background-color: white;
    mix-blend-mode: difference;
}  
.caret-animate{
    @extend .caret;
    animation: blink 1s step-end infinite; 
}
 
@keyframes blink{ 
    50% {
        opacity: 0;
      }
} 