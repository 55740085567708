@import "src/styles/mixins";
@import "src/styles/colors.module";
.featureInfoComponent {
  .featureInfoHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .featureInfoTitle {
      font-size: 1.75rem;
      font-weight: 700;
    }
    .featureInfoSubText {
      font-size: 1.25rem;
      font-weight: 500;
      color: $GREY-2;
    }
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $GREY-3;
    margin-bottom: 1.5rem;
  }
  .featureInfoDetails {
    display: flex;
    gap: 2.5rem;
    padding: 1rem 0rem;
    justify-content: flex-start;
    @include tablet-mobile {
      flex-direction: column;
      gap: 1.5rem;
    }
    .featureInfoDetailsLeft {
      .featureInfoDetailImage {
        max-width: 31.125rem;
        object-fit: contain;
        animation: reveal linear;
        animation-duration: 1s;
        animation-timeline: view(block);
        animation-range: entry 0% entry 100%;

        @keyframes reveal {
          from {
            transform: scale(0);
          }
          to {
            transform: scale(1);
          }
        }
        @include tablet {
          max-width: calc(100vw - 12.5rem);
        }
        @include mobile {
          max-width: calc(100vw - 2rem);
        }
        @include mobile-mini {
          max-width: calc(100vw - 2rem);
        }
      }
    }
    .featureInfoDetailsRight {
      padding: 1.5rem 0rem;
      font-size: 1.125rem;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: $GREY-2;
      @include tablet-mobile {
        padding: 0rem;
      }
    }
  }
  .featureInfoDetailsReverse {
    @extend .featureInfoDetails;
    flex-direction: row-reverse;
    .featureInfoDetailsLeft {
      text-align: right;
    }
    @include tablet-mobile {
      flex-direction: column;
    }
  }
}
