@import "src/styles/mixins";
@import "src/styles/colors.module";
.container {
  width: 100%;
  .topHeaderContainer {
    @include desktop-default-styles;
    @include tablet-default-styles;
    @include mobile-default-styles;
    background-color: $BLACK;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .leftSideContainer {
      max-width: 32rem;
      width: 100%;
      @include tablet-mobile {
        max-width: unset;
      }
    }
    .rightSideContainer {
      max-width: 32rem;
      position: relative;

      @include tablet-mobile {
        max-width: unset;
        .registerComponent {
          display: none;
          max-width: unset;
        }
      }
    }
    .headerTitle {
      color: $WHITE;
      font-size: 1.75rem;
      font-weight: 700;
    }
    .subText {
      font-size: 1.125rem;
      color: $EMPHASIZE-GREY;
      margin-top: 0.5rem;
    }
    .subInfo {
      margin-top: 3rem;
      display: flex;
      gap: 1.5rem;
    }
    .timePeriod {
      display: flex;
      gap: 0.25rem;
      color: $WHITE;
      font-weight: 400;
      font-size: 0.875rem;

      align-items: flex-end;
    }
  }
  .details {
    @include desktop-default-styles;
    @include tablet-default-styles;
    @include mobile-default-styles;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .innerDetails {
      max-width: 32rem;
      width: 100%;
      @include tablet-mobile {
        max-width: unset;
      }
    }
    .detailsContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &:not(:first-child) {
        margin-top: 2.5rem;
      }
      .title {
        font-size: 1rem;
        font-weight: 600;
      }
      .instructorDetails {
        display: flex;
        margin-top: 1rem;
        flex-direction: column;
        .intructorName {
          font-weight: 700;
        }
        .instructorRole {
          color: $GREY-2;
          font-weight: 400;
          font-size: 0.875rem;
        }
        .instructorExperience {
          color: $GREY-2;
          font-weight: 400;
          font-size: 0.875rem;
        }
        .instructorInfo {
          display: flex;
          gap: 0.5rem;
          align-items: center;
        }
      }

      .listContainer {
        margin: 0rem 1rem;
        .list {
          padding: 0rem 1rem;

          .listItem {
            color: $GREY-2;
            font-size: 0.875rem;
            font-weight: 400;
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.programPlans {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .cardsTitle {
    font-size: 1rem;
    font-weight: 600;
  }
  .cardsSubTitle {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .cardsList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.registerComponent {
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: $WHITE;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid $EMPHASIZE-GREY;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .badge {
    background-color: $PRIMARY-COLOR;
    border-radius: 0.875rem;
    color: $WHITE;
    padding: 0.25rem 1rem;
    width: auto;
    text-align: center;
  }
  .title {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    margin: 1rem 0 0.875rem;
  }
  .courseImage {
    object-fit: contain;
    width: 100%;
  }
  .buttonContainer {
    margin-top: 1.5rem;
    .limitedNotes {
      font-weight: 600;
      color: $PRIMARY-COLOR;
    }
  }
  .registerButton {
    width: 100% !important;
    margin-top: 0.5rem;
    text-align: center;
    min-width: 20rem;
    &:hover {
      cursor: pointer;
    }
  }
  @include tablet-mobile {
    position: unset;
    border: none;
    padding: 1rem 0rem;
  }
}

.registerTabMobContainer {
  display: none;

  @include tablet-mobile {
    display: block;
  }
}
