// Mobile mixin
@mixin mobile-mini {
  @media screen and (max-width: 504px) {
    @content;
  }
}
@mixin mobile-mini-default-styles {
  @media screen and (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin mobile-default-styles {
  @media screen and (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// Tablet mixin
@mixin tablet {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}
@mixin tablet-default-styles {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

// Desktop mixin
@mixin desktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop-default-styles {
  @media screen and (min-width: 1024px) {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

// Tablet and Mobile mixin
@mixin tablet-mobile {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

// @mixin tablet-mobile-default-styles {
//   @media screen and (min-width: 1024px) {
//     padding-left: 6.25rem;
//     padding-right: 6.25rem;
//   }
// }
