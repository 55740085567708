@import "src/styles/colors.module";

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.formInputItem {
  background: $WHITE-3;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  .icon {
    max-width: 1.5rem;
    max-height: 1.5rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    outline: none;
    border: 0;
    background: transparent;
  }
}
.formInputItemTextArea {
  @extend .formInputItem;
  textarea {
    width: 100%;
    outline: none;
    border: 0;
    background: transparent;
  }
}
