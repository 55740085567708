.buttonComponent {
  cursor: pointer;
  font-weight: 600;
  width: max-content;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  &:active {
    transform: scale(0.9);
  };
  &:hover {
    cursor: pointer;
  }
}