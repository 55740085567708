@import "src/styles/mixins";
@import "src/styles/colors.module";
.contactUsContainer {
  @include desktop-default-styles;
  @include tablet-default-styles;
  @include mobile-default-styles;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  // min-height: calc(100vh - 5.75rem);

  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  @include tablet-mobile {
    flex-direction: column;
  }
  .header {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .contactList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
  }
  .leftSide {
    margin-top: 1rem;
  }
  .contactDetailsContainer {
    display: flex;
    gap: 1rem;
    padding: 1.125rem 1rem 1rem;
    .icon {
      max-width: 3rem;
      width: 100%;
      max-height: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      color: $GREY-2;
      font-size: 0.875rem;
    }
  }
  .callUsContainer {
    background-color: $BRAND-PRIMARY;
    .icon {
      background-color: $PRIMARY-COLOR;
    }
  }
  .writeToUsContainer {
    background-color: $WHITE-2;
    .icon {
      background-color: $BLUE-1;
    }
  }
  .addressContainer {
    background-color: $WHITE-3;
    .icon {
      background-color: $BLACK;
    }
  }
  .rightSide {
    max-width: 37.5rem;
    width: 100%;
    @include tablet-mobile {
      max-width: unset;
    }
  }
  .writeToUsCard {
    border: 1px solid $WHITE-3;
    padding: 2.5rem 1.5rem 1.5rem;
    width: 100%;

    .writeToUsCardHeader {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .writeToUsCardButtonContainer {
      margin-top: 1.5rem;
      .writeToUsCardNote {
        color: $GREY-2;
        margin-top: 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
}
